import * as XLSX from 'xlsx'
import _ from 'lodash'

const readXlsx = (content) => {
  return XLSX.read(content, { type: 'binary' })
}

const sheetToJson = (sheet, headerRow) => {
  return XLSX.utils.sheet_to_json(sheet, { range: headerRow - 1 })
}

const asString = (x) => {
  if (typeof x === 'number') {
    return x.toString()
  } else {
    return x
  }
}

const validateInput = (input, regEx) => {
  if (!input && input !== 0) {
    return { empty: true, match: null }
  } else if (typeof input === 'number') {
    input = input.toString()
  } else if (typeof input !== 'string') {
    return { empty: false, match: null }
  }
  const match = input.match(regEx)
  return { empty: false, match: match ? match[0] : null }
}

const energyCarrierDict = {
  Heizöl: 'HEATING_OIL',
  Gas: 'NATURAL_GAS',
  Pellets: 'PELLET',
  Holzschnitzel: 'WOOD_CHIPS',
  Fernwärme: 'DISTRICT_HEAT',
  Nahwärme: 'LOCAL_HEAT',
  Solarthermie: 'SOLAR_HEAT',
  Netzstrom: 'ELECTRICITY_GRID',
  'PV-Strom': 'ELECTRICITY_PV',
  Stückholz: 'WOOD',
  'In der Liegenschaft definiertes Stromprodukt (Netz+PV)': 'ELECTRICITY_DYNAMIC_WITH_PV',
  'In der Liegenschaft definiertes Stromprodukt (Netz)': 'ELECTRICITY_DYNAMIC',
}

const parseBuildingsXlsx = (sheets, availableHeating) => {
  const distributionDict = {
    Bodenheizung: 'FLOOR_HEATING',
    Deckenheizung: 'CEILING_HEATING',
    Radiatoren: 'RADIATORS',
    Warmluf: 'AIR',
  }
  const roofDict = {
    Flach: 'FLAT',
    Giebel: 'GABLE',
  }
  const heatingDict = {
    Gas: 'GAS',
    Gasheizung: 'GAS',
    Öl: 'OIL',
    Ölheizung: 'OIL',
    Solarthermie: 'SOLAR',
    Solar: 'SOLAR',
    Fernwärme: 'DISTRICT',
    Pellets: 'PELLET',
    Pelletheizung: 'PELLET',
    Holzschnitzel: 'WOOD_CHIPS',
    Holzschnitzelheizung: 'WOOD_CHIPS',
    Holz: 'WOOD',
    Stückholz: 'WOOD',
    Elektrisch: 'ELECTRIC',
    Elektroheizung: 'ELECTRIC',
    'Luft-Wärmepumpe': 'HP_AIR',
    'Grundwasser-Wärmepumpe': 'HP_GW',
    'EWS-Wärmepumpe': 'HP_EWS',
    Unbekannt: 'UNKNOWN',
  }
  const usageDict = {
    'Wohnen MFH': 'RESIDENTIAL',
    'Wohnen EFH': 'RESIDENTIAL_SFH',
    'Büro/Verwaltung': 'OFFICE',
    Verwaltung: 'OFFICE',
    Büro: 'OFFICE',
    Schule: 'SCHOOLS',
    Verkauf: 'RETAIL',
    Restaurant: 'RESTAURANT',
    Versammlungslokal: 'EVENT_BUILDING',
    Veranstaltungslokal: 'EVENT_BUILDING',
    Spital: 'HOSPITAL',
    Industrie: 'INDUSTRIAL',
    Gewerbe: 'INDUSTRIAL',
    'Industrie/Gewerbe': 'INDUSTRIAL',
    Lager: 'STORAGE',
    Sport: 'SPORTS',
    Sportbaute: 'SPORTS',
    Hallenbad: 'INDOOR_SWIMMING',
    Unbekannt: 'UNKNOWN',
    Sonstiges: 'UNKNOWN',
  }
  const componentDict = {
    Fenster: 'windows',
    Kellerdecke: 'basement',
    Fassade: 'walls',
    Dach: 'roof',
  }
  const inputRegEx = {
    identifier: /^.{0,255}/,
    description: /^.{0,255}/,
    egids: /^\d+(?:, \d+)*$/,
    singleAddress:
      /([\u0020-\u002B\u002D-\u007E\u00C0-\u02B8]+?)(str\.|str)?(Str\.|Str)?\s+(\d+(?:\.\d+)?)\s*([a-zA-Z])?/,
    addresses:
      /^[\u0020-\u002B\u002D-\u007E\u00C0-\u02B8]+\s+\d+(?:\.\d+)?\s*\w?(?:[,\s][\u0020-\u002B\u002D-\u007E\u00C0-\u02B8]+\s+\d+(?:\.\d+)?\s*\w?)*\s*$/,
    zip_code: /^\d{4}$/,
    municipality: /^[\u0020-\u007E\u00C0-\u02B8]+$/,
    coordinates: /^\d+(?:\.\d+)?, \d+(?:\.\d+)?$/,
    roof_type: /^(?:Flach|Giebel)$/,
    year: /^\d{4}$/,
    float: /^\d+(?:\.\d+)?$/,
    int: /^\d+$/,
    remarks: /^.+$/,
    usage:
      /^(?:Wohnen MFH|Wohnen EFH|Verwaltung|Büro|Büro\/Verwaltung|Schule|Verkauf|Restaurant|Versammlungslokal|Veranstaltungslokal|Spital|Industrie|Industrie\/Gewerbe|Gewerbe|Lager|Sportbaute|Sport|Hallenbad|Unbekannt|Sonstiges)$/,
    distribution: /^(?:Bodenheizung|Deckenheizung|Radiatoren|Warmluft)$/,
    singleTag:
      /\s*([\u0020-\u002B\u002D-\u0039\u003B-\u007E\u00C0-\u02B8]+)\s*:\s*([\u0020-\u002B\u002D-\u0039\u003B-\u007E\u00C0-\u02B8]+)\s*/,
    tags: /^\s*([\u0020-\u002B\u002D-\u0039\u003B-\u007E\u00C0-\u02B8]+)\s*:\s*([\u0020-\u002B\u002D-\u0039\u003B-\u007E\u00C0-\u02B8]+)\s*(?:,\s+([\u0020-\u002B\u002D-\u0039\u003B-\u007E\u00C0-\u02B8]+)\s*:\s*([\u0020-\u002B\u002D-\u0039\u003B-\u007E\u00C0-\u02B8]+)\s*)*$/,
    heating_type: /^.{0,255}/,
    component_type: /^(?:Fenster|Kellerdecke|Fassade|Dach)$/,
  }
  let buildings = {}
  let tagGroups = []
  let input = {
    generalSheet: [],
    heatingSheet: [],
    pvSheet: [],
    distributionSheet: [],
    componentSheet: [],
    zoneSheet: [],
    heaterSheet: [],
    missingEntries: {
      zoneSheet: [],
      heaterSheet: [],
    },
    missingSheets: [],
  }
  let warning = false
  let fatal = false

  if (sheets.generalSheet) {
    sheets.generalSheet.forEach((row, index) => {
      let building = {
        description: null,
        remarks: '',
        egids: null,
        addresses: null,
        zip_code: null,
        municipality: null,
        active_from: null,
        active_to: null,
        latitude: null,
        longitude: null,
        tags: [],
        initial_state: {
          envelope: {
            basement: {
              year: null,
              replacement_year: null,
              replacement_costs: null,
              area: null,
              uvalue: null,
              remarks: null,
            },
            roof: {
              year: null,
              replacement_costs: null,
              area: null,
              uvalue: null,
              remarks: null,
            },
            walls: {
              year: null,
              replacement_costs: null,
              area: null,
              uvalue: null,
              remarks: null,
            },
            windows: {
              year: null,
              replacement_costs: null,
              area: null,
              uvalue: null,
              remarks: null,
            },
          },
          heating: {
            year: null,
            replacement_year: null,
            remarks: null,
            heaters: [],
            distribution: 'UNKNOWN',
            distribution_year: null,
            distribution_replacement_year: null,
            distribution_remarks: null,
          },
          zones: { zones: [] },
          pv: {
            sections: [],
            year: null,
            remarks: null,
          },
        },
      }
      let buildingError = {
        addresses: false,
        zip_code: false,
        identifier: false,
        description: false,
        municipality: false,
        remarks: false,
        building_year: false,
        active_from: false,
        active_to: false,
        floors_number: false,
        roof_type: false,
        egids: false,
        coordinates: false,
      }
      let error = false

      const zipCode = validateInput(row.zip_code, inputRegEx.zip_code)
      if (zipCode.match) {
        building.zip_code = row.zip_code.toString()
      } else {
        buildingError.zip_code = true
        fatal = true
        error = true
      }

      const identifier = validateInput(row.identifier, inputRegEx.identifier)
      if (identifier.match) {
        if (buildings[asString(row.identifier)] !== undefined) {
          buildingError.identifier = true
          fatal = true
          error = true
        } else {
          building.identifier = row.identifier
        }
      } else {
        buildingError.identifier = true
        fatal = true
        error = true
      }

      const description = validateInput(row.description, inputRegEx.description)
      if (description.match) {
        building.description = asString(row.description)
      } else if (!description.empty) {
        buildingError.description = true
        warning = true
        error = true
      }

      const municipality = validateInput(row.municipality, inputRegEx.municipality)
      if (municipality.match) {
        building.municipality = row.municipality
      } else if (!municipality.empty) {
        buildingError.municipality = true
        warning = true
        error = true
      }

      const remarks = validateInput(row.remarks, inputRegEx.remarks)
      if (remarks.match) {
        building.remarks = asString(row.remarks)
      } else if (!remarks.empty) {
        buildingError.remarks = true
        warning = true
        error = true
      }

      const buildingYear = validateInput(row.building_year, inputRegEx.year)
      if (buildingYear.match && row.building_year <= 2100) {
        building.initial_state.envelope.building_year = row.building_year
      } else if (!buildingYear.empty) {
        buildingError.building_year = true
        warning = true
        error = true
      }

      const activeFrom = validateInput(row.active_from, inputRegEx.year)
      if (activeFrom.match && row.active_from <= 2100) {
        if (!buildingError.building_year && row.active_from < row.building_year) {
          buildingError.active_from = true
          warning = true
          error = true
        } else {
          building.active_from = row.active_from
        }
      } else if (!activeFrom.empty) {
        buildingError.active_from = true
        warning = true
        error = true
      }

      const activeTo = validateInput(row.active_to, inputRegEx.year)
      if (activeTo.match && row.active_to <= 2100) {
        if (building.active_from && building.active_from > row.active_to) {
          building.active_from = null
          buildingError.active_to = true
          buildingError.active_from = true
          warning = true
          error = true
        } else if (!buildingError.building_year && row.active_to < row.building_year) {
          buildingError.active_to = true
          warning = true
          error = true
        } else {
          building.active_to = row.active_to
        }
      } else if (!activeTo.empty) {
        buildingError.active_to = true
        warning = true
        error = true
      }

      const floorsNumber = validateInput(row.floors_number, inputRegEx.int)
      if (floorsNumber.match) {
        building.initial_state.envelope.floors_number = row.floors_number
      } else if (!floorsNumber.empty) {
        buildingError.floors_number = true
        warning = true
        error = true
      }

      const roofType = validateInput(row.roof_type, inputRegEx.roof_type)
      if (roofType.match) {
        building.initial_state.envelope.roof_type = roofDict[row.roof_type]
      } else if (!roofType.empty) {
        buildingError.roof_type = true
        warning = true
        error = true
      }

      const addresses = validateInput(row.addresses, inputRegEx.addresses)
      if (addresses.match) {
        let addressArray = row.addresses.split(',')
        building.addresses = addressArray
          .map((address) => {
            let addressMatch = address.match(inputRegEx.singleAddress)
            return (
              addressMatch[1] +
              (addressMatch[2] !== undefined ? 'strasse' : '') +
              (addressMatch[3] !== undefined ? 'Strasse' : '') +
              ' ' +
              addressMatch[4] +
              (addressMatch[5] !== undefined ? addressMatch[5].toLowerCase() : '')
            )
          })
          .join(';')
      } else {
        buildingError.addresses = true
        fatal = true
        error = true
      }

      const egids = validateInput(row.egids, inputRegEx.egids)
      if (egids.match) {
        if (typeof row.egids === 'string') {
          building.egids = row.egids.replaceAll(/, /g, ';')
        } else {
          building.egids = row.egids.toString()
        }
      } else if (!egids.empty) {
        buildingError.egids = true
        warning = true
        error = true
      }

      const coordinates = validateInput(row.coordinates, inputRegEx.coordinates)
      if (coordinates.match) {
        const coords = row.coordinates.split(', ')
        building.latitude = coords[0]
        building.longitude = coords[1]
      } else if (!coordinates.empty) {
        buildingError.coordinates = true
        warning = true
        error = true
      }

      const tags = validateInput(row.tags, inputRegEx.tags)
      if (tags.match) {
        let tagsArray = row.tags.split(',')
        for (let i = 0; i < tagsArray.length; i++) {
          let tag = tagsArray[i].match(inputRegEx.singleTag)
          if (tag) {
            let tagGroup = tagGroups.find((group) => group.name === tag[1])
            if (tagGroup === undefined) {
              tagGroups.push({
                name: tag[1],
                tags: [tag[2]],
              })
            } else {
              if (tagGroup['tags'].find((t) => t === tag[2]) === undefined) {
                tagGroup['tags'].push(tag[2])
              }
            }
            building.tags.push({
              gname: tag[1],
              name: tag[2],
            })
          } else {
            buildingError.tags = true
            error = true
            warning = true
          }
        }
      } else if (!tags.empty) {
        buildingError.tags = true
        error = true
        warning = true
      }

      buildings[asString(building.identifier)] = building

      if (error) {
        input.generalSheet.push({
          input: row,
          error: buildingError,
        })
      }
    })
  }

  if (sheets.heatingSheet) {
    sheets.heatingSheet.forEach((row, index) => {
      let error = false
      if (index < sheets.generalSheet.length) {
        let building = buildings[asString(row.identifier)]
        let buildingError = {
          year: false,
          replacement_year: false,
          remarks: false,
        }

        const year = validateInput(row.year, inputRegEx.year)
        if (year.match && row.year <= 2100) {
          building.initial_state.heating.year = row.year
        } else if (!year.empty) {
          buildingError.year = true
          warning = true
          error = true
        }

        const replacementYear = validateInput(row.replacement_year, inputRegEx.year)
        if (replacementYear.match && row.replacement_year <= 2100) {
          building.initial_state.heating.replacement_year = row.replacement_year
        } else if (!replacementYear.empty) {
          buildingError.replacement_year = true
          warning = true
          error = true
        }

        const remarks = validateInput(row.remarks, inputRegEx.remarks)
        if (remarks.match) {
          building.initial_state.heating.remarks = asString(row.remarks)
        } else if (!remarks.empty) {
          buildingError.remarks = true
          warning = true
          error = true
        }

        if (error) {
          input.heatingSheet.push({
            input: row,
            error: buildingError,
          })
        }
      }
    })
  }

  if (sheets.pvSheet) {
    sheets.pvSheet.forEach((row, index) => {
      let error = false
      if (index < sheets.generalSheet.length) {
        let building = buildings[asString(row.identifier)]
        let buildingError = {
          year: false,
          capacity: false,
          production_manual: false,
          remarks: false,
        }

        const year = validateInput(row.year, inputRegEx.year)
        if (year.match && row.year <= 2100) {
          building.initial_state.pv.year = row.year
        } else if (!year.empty) {
          buildingError.year = true
          warning = true
          error = true
        }

        const capacity = validateInput(row.capacity, inputRegEx.float)
        const productionManual = validateInput(row.production_manual, inputRegEx.float)

        if (capacity.match) {
          let section = {
            capacity: row.capacity,
            orientation: 0,
            angle: 10,
          }
          if (productionManual.match) {
            section['production_manual'] = row.production_manual
          } else {
            buildingError.production_manual = true
            warning = true
            error = true
          }
          building.initial_state.pv.sections = [section]
        } else if (!productionManual.empty || !capacity.empty) {
          buildingError.capacity = true
          warning = true
          error = true
          if (!productionManual.match) {
            buildingError.production_manual = true
          }
        }

        const remarks = validateInput(row.remarks, inputRegEx.remarks)
        if (remarks.match) {
          building.initial_state.pv.remarks = asString(row.remarks)
        } else if (!remarks.empty) {
          buildingError.remarks = true
          warning = true
          error = true
        }

        if (error) {
          input.pvSheet.push({
            input: row,
            error: buildingError,
          })
        }
      }
    })
  }

  if (sheets.distributionSheet) {
    sheets.distributionSheet.forEach((row, index) => {
      let error = false
      if (index < sheets.generalSheet.length) {
        let building = buildings[asString(row.identifier)]
        let buildingError = {
          year: false,
          distribution: false,
          replacement_year: false,
          remarks: false,
        }

        const year = validateInput(row.year, inputRegEx.year)
        if (year.match && row.year <= 2100) {
          building.initial_state.heating.distribution_year = row.year
        } else if (!year.empty) {
          buildingError.year = true
          warning = true
          error = true
        }

        const replacementYear = validateInput(row.replacement_year, inputRegEx.year)
        if (replacementYear.match && row.replacement_year <= 2100) {
          building.initial_state.heating.distribution_replacement_year = row.replacement_year
        } else if (!replacementYear.empty) {
          buildingError.replacement_year = true
          warning = true
          error = true
        }

        const distribution = validateInput(row.distribution, inputRegEx.distribution)
        if (distribution.match) {
          building.initial_state.heating.distribution = distributionDict[row.distribution]
        } else if (!distribution.empty) {
          buildingError.distribution = true
          warning = true
          error = true
        }

        const remarks = validateInput(row.remarks, inputRegEx.remarks)
        if (remarks.match) {
          building.initial_state.heating.distribution_remarks = asString(row.remarks)
        } else if (!remarks.empty) {
          buildingError.remarks = true
          warning = true
          error = true
        }

        if (error) {
          input.distributionSheet.push({
            input: row,
            error: buildingError,
          })
        }
      }
    })
  }

  if (sheets.componentSheet) {
    sheets.componentSheet.forEach((row) => {
      let error = false
      let component = {
        year: null,
        replacement_year: null,
        area: null,
        uvalue: null,
        remarks: null,
      }
      let buildingError = {
        identifier: false,
        type: false,
        area: false,
        remarks: false,
        replacement_costs: false,
        replacement_year: false,
        uvalue: false,
        year: false,
      }

      const year = validateInput(row.year, inputRegEx.year)
      if (year.match && row.year <= 2100) {
        component.year = row.year
      } else if (!year.empty) {
        buildingError.year = true
        warning = true
        error = true
      }

      const replacementYear = validateInput(row.replacement_year, inputRegEx.year)
      if (replacementYear.match && row.replacement_year <= 2100) {
        component.replacement_year = row.replacement_year
      } else if (!replacementYear.empty) {
        buildingError.replacement_year = true
        warning = true
        error = true
      }

      const replacementCosts = validateInput(row.replacement_costs, inputRegEx.float)
      if (replacementCosts.match) {
        component.replacement_costs = row.replacement_costs
      } else if (!replacementCosts.empty) {
        buildingError.replacement_costs = true
        warning = true
        error = true
      }

      const area = validateInput(row.area, inputRegEx.float)
      if (area.match) {
        component.area = row.area
      } else if (!area.empty) {
        buildingError.area = true
        warning = true
        error = true
      }

      const uvalue = validateInput(row.uvalue, inputRegEx.float)
      if (uvalue.match) {
        component.uvalue = row.uvalue
      } else if (!uvalue.empty) {
        buildingError.uvalue = true
        warning = true
        error = true
      }

      const remarks = validateInput(row.remarks, inputRegEx.remarks)
      if (remarks.match) {
        component.remarks = asString(row.remarks)
      } else if (!remarks.empty) {
        buildingError.remarks = true
        warning = true
        error = true
      }

      const type = validateInput(row.type, inputRegEx.component_type)
      if (!type.match) {
        buildingError.type = true
        warning = true
        error = true
      }

      const identifier = validateInput(row.identifier, inputRegEx.identifier)
      if (identifier.match) {
        let building = buildings[asString(row.identifier)]

        if (!building) {
          buildingError.identifier = true
          warning = true
          error = true
        } else if (type.match) {
          building.initial_state.envelope[componentDict[row.type]] = component
        }
      } else {
        buildingError.identifier = true
        warning = true
        error = true
      }

      if (error) {
        input.componentSheet.push({
          input: row,
          error: buildingError,
        })
      }
    })
  }
  if (sheets.heaterSheet) {
    let heaterShares = _.keys(buildings).reduce((newObj, identifier) => {
      newObj[identifier] = {
        identifier,
        rh: 0,
        dhw: 0,
      }
      return newObj
    }, {})
    sheets.heaterSheet.forEach((row) => {
      let error = false
      let heater = {}
      let buildingError = {
        identifier: false,
        type: false,
        rh_share: false,
        dhw_share: false,
        ghg_factor: false,
        pe_factor: false,
        energy_calibration: false,
      }

      const type = validateInput(row.type, inputRegEx.heating_type)
      const name = row.type in heatingDict ? heatingDict[row.type] : row.type
      const htMatch = availableHeating.filter((ht) => ht.name === name)
      if (type.match && htMatch.length >= 1) {
        heater.heating_type_id = htMatch[0].name
      } else {
        buildingError.type = true
        fatal = true
        error = true
      }

      const rhShare = validateInput(row.rh_share, inputRegEx.float)
      if (rhShare.match) {
        heater.rh_share = row.rh_share
      } else {
        buildingError.rh_share = true
        fatal = true
        error = true
      }

      const dhwShare = validateInput(row.dhw_share, inputRegEx.float)
      if (dhwShare.match) {
        heater.dhw_share = row.dhw_share
      } else {
        buildingError.dhw_share = true
        fatal = true
        error = true
      }

      const ghgFactor = validateInput(row.ghg_factor, inputRegEx.float)
      if (ghgFactor.match) {
        heater.ghg_factor = row.ghg_factor
      } else if (!ghgFactor.empty) {
        buildingError.ghg_factor = true
        warning = true
        error = true
      }

      const peFactor = validateInput(row.pe_factor, inputRegEx.float)
      if (peFactor.match) {
        heater.pe_factor = row.pe_factor
      } else if (!peFactor.empty) {
        buildingError.pe_factor = true
        warning = true
        error = true
      }

      const energyCalibration = validateInput(row.energy_calibration, inputRegEx.float)
      if (energyCalibration.match) {
        heater.energy_calibration = row.energy_calibration
      } else if (!energyCalibration.empty) {
        buildingError.energy_calibration = true
        warning = true
        error = true
      }

      const identifier = validateInput(row.identifier, inputRegEx.identifier)
      if (identifier.match) {
        const identifierKey = asString(row.identifier)
        let building = buildings[identifierKey]

        if (!building) {
          buildingError.identifier = true
          warning = true
          error = true
        } else if (rhShare.match && dhwShare.match) {
          building.initial_state.heating.heaters.push(heater)
          heaterShares[identifierKey].rh += row.rh_share
          heaterShares[identifierKey].dhw += row.dhw_share
        }
      } else {
        buildingError.identifier = true
        warning = true
        error = true
      }

      if (error) {
        input.heaterSheet.push({
          input: row,
          error: buildingError,
        })
      }
    })
    _.values(heaterShares).forEach((building) => {
      if (building.rh !== 100 || building.dhw !== 100) {
        input.missingEntries.heaterSheet.push(building)
      }
    })
  }

  if (sheets.zoneSheet) {
    sheets.zoneSheet.forEach((row) => {
      let error = false
      let zone = {}
      let buildingError = {
        identifier: false,
        usage: false,
        energy_area: false,
        rentable_area: false,
        rental_area: false,
        floor_area: false,
        dhw_demand: false,
        el_tenants: false,
        el_general: false,
      }

      const usage = validateInput(row.usage, inputRegEx.usage)
      if (usage.match) {
        zone.usage = usageDict[row.usage]
      } else {
        buildingError.usage = true
        fatal = true
        error = true
      }

      const energyArea = validateInput(row.energy_area, inputRegEx.int)
      if (energyArea.match) {
        zone.energy_area = {
          manual: row.energy_area,
        }
      } else if (!energyArea.empty) {
        buildingError.energy_area = true
        warning = true
        error = true
      }

      const rentableArea = validateInput(row.rentable_area, inputRegEx.int)
      if (rentableArea.match) {
        zone.rentable_area = {
          manual: row.rentable_area,
        }
      } else if (!rentableArea.empty) {
        buildingError.rentable_area = true
        warning = true
        error = true
      }

      const rentalArea = validateInput(row.rental_area, inputRegEx.int)
      if (rentalArea.match) {
        zone.rental_area = row.rental_area
      } else if (!rentalArea.empty) {
        buildingError.rental_area = true
        warning = true
        error = true
      }

      const floorArea = validateInput(row.floor_area, inputRegEx.int)
      if (floorArea.match) {
        zone.floor_area = {
          manual: row.floor_area,
        }
      } else if (!floorArea.empty) {
        buildingError.floor_area = true
        warning = true
        error = true
      }

      const dhwDemand = validateInput(row.dhw_demand, inputRegEx.float)
      if (dhwDemand.match) {
        zone.dhw_demand = {
          manual: row.dhw_demand,
        }
      } else if (!dhwDemand.empty) {
        buildingError.dhw_demand = true
        warning = true
        error = true
      }

      const elTenants = validateInput(row.el_tenants, inputRegEx.float)
      if (elTenants.match) {
        zone.el_tenants = {
          manual: row.el_tenants,
        }
      } else if (!elTenants.empty) {
        buildingError.el_tenants = true
        warning = true
        error = true
      }

      const elGeneral = validateInput(row.el_general, inputRegEx.float)
      if (elGeneral.match) {
        zone.el_general = {
          manual: row.el_general,
        }
      } else if (!elGeneral.empty) {
        buildingError.el_general = true
        warning = true
        error = true
      }

      const identifier = validateInput(row.identifier, inputRegEx.identifier)
      if (identifier.match) {
        let building = buildings[asString(row.identifier)]

        if (!building) {
          buildingError.identifier = true
          fatal = true
          error = true
        } else {
          building.initial_state.zones.zones.push(zone)
        }
      } else {
        buildingError.identifier = true
        warning = true
        error = true
      }

      if (error) {
        input.zoneSheet.push({
          input: row,
          error: buildingError,
        })
      }
    })
    _.values(buildings).forEach((building) => {
      if (building.initial_state.zones.zones.length === 0 && building.identifier) {
        fatal = true
        input.missingEntries.zoneSheet.push(building.identifier)
      }
    })
  }
  return { buildings: _.values(buildings), tagGroups, input, warning, fatal }
}

const parseMeterTemplateXlsx = (sheet, buildingIds, energyCarrierNameList) => {
  let values = []
  let input = []
  sheet.forEach((row) => {
    let error = false
    let rowError = {
      id: false,
      meter_type: false,
      energy_carrier: false,
      identifier: false,
      start_month: false,
      remarks: false,
      amount: false,
    }

    let meterValue = {
      identifier: null,
      start_month: 1,
      remarks: null,
    }

    if (row.id) {
      let idString = isNaN(row.id) ? row.id : row.id.toString()
      if (buildingIds[idString]) {
        meterValue.id = buildingIds[idString]
      } else {
        rowError.id = true
        error = true
      }
    } else {
      rowError.id = true
      error = true
    }

    if (row.meter_type) {
      switch (row.meter_type) {
        case 'Endenergie Wärmeerzeuger':
          meterValue.meter_type = 'END_ENERGY_HEATER'
          break
        case 'Mieterstrom':
          meterValue.meter_type = 'ELECTRICITY_TENANTS'
          break
        case 'Allgemeinstrom':
          meterValue.meter_type = 'ELECTRICITY_GENERAL'
          break
        case 'Produktion Photovoltaik':
          meterValue.meter_type = 'ELECTRICITY_PV'
          break
        default:
          rowError.meter_type = true
          error = true
      }
    } else {
      rowError.meter_type = true
      error = true
    }

    if (row.energy_carrier) {
      if (Object.keys(energyCarrierDict).includes(row.energy_carrier)) {
        meterValue.energy_carrier = energyCarrierDict[row.energy_carrier]
      } else if (energyCarrierNameList.includes(row.energy_carrier)) {
        meterValue.energy_carrier = row.energy_carrier
      } else if (meterValue.meter_type === 'END_ENERGY_HEATER') {
        rowError.energy_carrier = true
        error = true
      } else {
        meterValue.energy_carrier = 'HEATING_OIL'
      }
    } else if (rowError.meter_type || meterValue.meter_type === 'END_ENERGY_HEATER') {
      rowError.energy_carrier = true
      error = true
    } else {
      meterValue.energy_carrier = 'HEATING_OIL'
    }

    if (row.amount && !isNaN(row.amount)) {
      meterValue.amount = row.amount
    } else {
      rowError.amount = true
      error = true
    }

    if (row.identifier) {
      if (isNaN(row.identifier)) {
        meterValue.identifier = row.identifier
      } else {
        meterValue.identifier = row.identifier.toString()
      }
    }

    if (row.start_month) {
      if (isNaN(row.start_month)) {
        rowError.start_month = true
        error = true
      } else {
        meterValue.start_month = row.start_month
      }
    }

    if (row.remarks) {
      meterValue.remarks = row.remarks
    }

    if (!rowError.amount && !rowError.meter_type && !rowError.id && !rowError.energy_carrier) {
      values.push(meterValue)
    }

    if (error) {
      input.push({
        input: row,
        error: rowError,
      })
    }
  })

  return { values, input }
}

const parseAltTemplate1Xlsx = (sheet, buildingIds) => {
  const attributeNames = {
    oil: 'Heizöl\r\nVerbrauch\r\nin Liter',
    gas: 'Lieferung Gas\r\nKonto 18470\r\nin kWh',
    districHeating: 'Lieferung\r\nFernwärme\r\nKonto 18460\r\nin kWh',
    heating1: 'Heizstrom\r\nKonto 18520\r\nin kWh',
    heating2: 'Heizstrom\r\nKonto 41282\r\nin kWh',
    general1: 'Strom Allgemein\r\nKonto Nr. 18100\r\nin kWh',
    general2: 'Strom Allgemein\r\nKonto Nr. 41100\r\nin kWh',
    garage1: 'Strom Garage\r\nKonto Nr. 18103\r\nin kWh',
    garage2: 'Strom Garage\r\nKonto Nr. 41103\r\nin kWh',
    vacancy: 'Strom Leerstand\r\nKonto Nr. 41115\r\nin kWh',
    identifier: 'Lieg-Nr',
  }
  let values = []
  let input = []
  sheet.forEach((row) => {
    let error = false
    let rowError = {
      identifier: false,
      gas: false,
      districHeating: false,
      heating1: false,
      heating2: false,
      general1: false,
      general2: false,
      garage1: false,
      garage2: false,
      vacancy: false,
    }
    let id = null
    let r = {}
    r.identifier = row[attributeNames['identifier']]
    if (attributeNames['identifier'] in row) {
      let identifier = ''
      if (isNaN(row[attributeNames['identifier']])) {
        identifier = row[attributeNames['identifier']]
      } else {
        identifier = row[attributeNames['identifier']].toString()
      }
      if (buildingIds[identifier]) {
        id = buildingIds[identifier]
      } else {
        rowError.identifier = true
        error = true
      }
    } else {
      rowError.identifier = true
      error = true
    }

    r.oil = row[attributeNames['oil']]
    if (attributeNames['oil'] in row) {
      if (isNaN(row[attributeNames['oil']])) {
        rowError.oil = true
        error = true
      } else if (id !== null) {
        values.push({
          id: id,
          amount: row[attributeNames['oil']],
          convert: true,
          meter_type: 'END_ENERGY_HEATER',
          energy_carrier: 'HEATING_OIL',
        })
      }
    }

    r.gas = row[attributeNames['gas']]
    if (attributeNames['gas'] in row) {
      if (isNaN(row[attributeNames['gas']])) {
        rowError.gas = true
        error = true
      } else if (id !== null) {
        values.push({
          id: id,
          amount: row[attributeNames['gas']],
          meter_type: 'END_ENERGY_HEATER',
          energy_carrier: 'NATURAL_GAS',
        })
      }
    }

    r.districHeating = row[attributeNames['districHeating']]
    if (attributeNames['districHeating'] in row) {
      if (isNaN(row[attributeNames['districHeating']])) {
        rowError.districHeating = true
        error = true
      } else if (id !== null) {
        values.push({
          id: id,
          amount: row[attributeNames['districHeating']],
          meter_type: 'END_ENERGY_HEATER',
          energy_carrier: 'DISTRICT_HEAT',
        })
      }
    }

    let heating = {
      id: id,
      amount: -1,
      meter_type: 'END_ENERGY_HEATER',
      energy_carrier: 'ELECTRICITY',
    }
    let heatingError = false

    r.heating1 = row[attributeNames['heating1']]
    if (attributeNames['heating1'] in row) {
      if (isNaN(row[attributeNames['heating1']])) {
        rowError.heating1 = true
        heatingError = true
      } else if (id !== null) {
        heating.amount = (heating.amount === -1 ? 0 : heating.amount) + row[attributeNames['heating1']]
      }
    }

    r.heating2 = row[attributeNames['heating2']]
    if (attributeNames['heating2'] in row) {
      if (isNaN(row[attributeNames['heating2']])) {
        rowError.heating2 = true
        heatingError = true
      } else if (id !== null) {
        heating.amount = (heating.amount === -1 ? 0 : heating.amount) + row[attributeNames['heating1']]
      }
    }

    if (heatingError) {
      error = true
    } else if (heating.amount !== -1) {
      values.push(heating)
    }

    let general = {
      id: id,
      meter_type: 'ELECTRICITY_GENERAL',
      energy_carrier: 'HEATING_OIL',
      amount: -1,
    }
    let generalError = false

    r.vacancy = row[attributeNames['vacancy']]
    if (attributeNames['vacancy'] in row) {
      if (isNaN(row[attributeNames['vacancy']])) {
        rowError.vacancy = true
        generalError = true
      } else if (id !== null) {
        general.amount = (general.amount === -1 ? 0 : general.amount) + row[attributeNames['vacancy']]
      }
    }

    r.general1 = row[attributeNames['general1']]
    if (attributeNames['general1'] in row) {
      if (isNaN(row[attributeNames['general1']])) {
        rowError.general1 = true
        generalError = true
      } else if (id !== null) {
        general.amount = (general.amount === -1 ? 0 : general.amount) + row[attributeNames['general1']]
      }
    }

    r.general2 = row[attributeNames['general2']]
    if (attributeNames['general2'] in row) {
      if (isNaN(row[attributeNames['general2']])) {
        rowError.general2 = true
        generalError = true
      } else if (id !== null) {
        general.amount = (general.amount === -1 ? 0 : general.amount) + row[attributeNames['general2']]
      }
    }

    r.garage1 = row[attributeNames['garage1']]
    if (attributeNames['garage1'] in row) {
      if (isNaN(row[attributeNames['garage1']])) {
        rowError.general1 = true
        generalError = true
      } else if (id !== null) {
        general.amount = (general.amount === -1 ? 0 : general.amount) + row[attributeNames['garage1']]
      }
    }

    r.garage2 = row[attributeNames['garage2']]
    if (attributeNames['garage2'] in row) {
      if (isNaN(row[attributeNames['garage2']])) {
        rowError.general1 = true
        generalError = true
      } else if (id !== null) {
        general.amount = (general.amount === -1 ? 0 : general.amount) + row[attributeNames['garage2']]
      }
    }

    if (generalError) {
      error = true
    } else if (general.amount !== -1) {
      values.push(general)
    }

    if (error) {
      input.push({
        input: r,
        error: rowError,
      })
    }
  })
  return { values, input }
}

const parseAltTemplate2Xlsx = (sheet, buildingIds) => {
  const attributeNames = {
    identifier: 'LS-Nr.',
    heater1: 'Heizenergieträger 1',
    heater2: 'Heizenergieträger 2',
    description: 'Description',
    location: 'Location',
  }
  let values = []
  let input = []
  sheet.forEach((row) => {
    let error = false
    let rowError = {
      identifier: false,
      heater1: false,
      heater2: false,
      years: [],
    }
    let id = null
    let r = {}
    r.location = row[attributeNames['location']]
    r.identifier = row[attributeNames['identifier']]
    if (attributeNames['identifier'] in row) {
      let identifier = ''
      if (isNaN(row[attributeNames['identifier']])) {
        identifier = row[attributeNames['identifier']]
      } else {
        identifier = row[attributeNames['identifier']].toString()
      }
      if (buildingIds[identifier]) {
        id = buildingIds[identifier]
      } else {
        rowError.identifier = true
        error = true
      }
    } else {
      rowError.identifier = true
      error = true
    }
    let meter1 = {
      id: id,
      values: [],
    }
    let meter2 = {
      id: id,
      meter_type: 'END_ENERGY_HEATER',
      values: [],
    }
    r.description = row[attributeNames['description']]
    if (attributeNames['description'] in row) {
      if (isNaN(row[attributeNames['description']])) {
        switch (row[attributeNames['description']]) {
          case 'Wärmebezug Total':
            meter1.meter_type = 'END_ENERGY_HEATER'
            meter2.meter_type = 'END_ENERGY_HEATER'
            break
          case 'Strombezug Total':
            meter1.meter_type = 'ELECTRICITY_GENERAL'
            meter2.meter_type = 'ELECTRICITY_GENERAL'
            meter1.energy_carrier = 'HEATING_OIL'
            meter2.energy_carrier = 'HEATING_OIL'
            break
          default:
            rowError.description = true
            error = true
            break
        }
      } else {
        rowError.description = true
        error = true
      }
    } else {
      rowError.description = true
      error = true
    }
    r.heater1 = row[attributeNames['heater1']]
    if (attributeNames['heater1'] in row) {
      if (isNaN(row[attributeNames['heater1']])) {
        switch (row[attributeNames['heater1']]) {
          case 'Erdgas':
            meter1.energy_carrier = 'NATURAL_GAS'
            break
          case 'Heizöl':
            meter1.energy_carrier = 'HEATING_OIL'
            break
          case 'Fernwärme':
            meter1.energy_carrier = 'DISTRICT_HEAT'
            break
          case 'Pellet':
            meter1.energy_carrier = 'PELLET'
            break
          default:
            rowError.heater1 = true
            error = true
            break
        }
      } else {
        rowError.heater1 = true
        error = true
      }
    }
    r.heater2 = row[attributeNames['heater2']]
    if (attributeNames['heater2'] in row) {
      if (isNaN(row[attributeNames['heater2']])) {
        switch (row[attributeNames['heater2']]) {
          case 'Erdgas':
            meter2.energy_carrier = 'NATURAL_GAS'
            break
          case 'Heizöl':
            meter2.energy_carrier = 'HEATING_OIL'
            break
          case 'Fernwärme':
            meter2.energy_carrier = 'DISTRICT_HEAT'
            break
          case 'Pellet':
            meter2.energy_carrier = 'PELLET'
            break
          default:
            rowError.heater2 = true
            error = true
            break
        }
      } else {
        rowError.heater2 = true
        error = true
      }
    }
    const heaterDict = {
      Gas: 'NATURAL_GAS',
      Heizöl: 'HEATING_OIL',
      Erdgas: 'NATURAL_GAS',
      Fernwärme: 'DISTRICT_HEAT',
      Pellet: 'PELLET',
    }
    r.y2020 = row['2020']
    if ('2020' in row) {
      if (isNaN(row['2020'])) {
        let match = row['2020'].match(
          /^(\d*)\.?(\d*)? \((?:davon (\d+)\.?(\d+)? WP|(\d+)\.?(\d+)? ([A-Za-zö]+), Rest = ([A-Za-zö]+)|([A-Za-zö]+))\)$/
        )
        if (match) {
          if (match[9]) {
            if (meter1.energy_carrier === heaterDict[match[9]]) {
              meter1.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]),
                year: 2020,
              })
            } else if (meter2.energy_carrier === heaterDict[match[9]]) {
              meter2.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]),
                year: 2020,
              })
            } else {
              rowError.years.push(2020)
              error = true
            }
          } else if (match[7] && match[8]) {
            if (meter1.energy_carrier === heaterDict[match[7]]) {
              meter1.values.push({
                amount: parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2020,
              })
            }
            if (meter2.energy_carrier === heaterDict[match[7]]) {
              meter2.values.push({
                amount: parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2020,
              })
            }
            if (meter1.energy_carrier === heaterDict[match[8]]) {
              meter1.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]) - parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2020,
              })
            }
            if (meter2.energy_carrier === heaterDict[match[8]]) {
              meter2.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]) - parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2020,
              })
            }
          } else if (match[3]) {
            meter1.values.push({
              amount: parseInt(match[1]) + parseInt('0.' + match[2]) - parseInt(match[3]) + parseInt('0.' + match[4]),
              year: 2020,
            })
          }
        } else {
          rowError.years.push(2020)
        }
      } else {
        meter1.values.push({
          amount: row['2020'],
          year: 2020,
        })
      }
    }
    r.y2021 = row['2021']
    if ('2021' in row) {
      if (isNaN(row['2021'])) {
        let match = row['2021'].match(
          /^(\d*)\.?(\d*)? \((?:davon (\d+)\.?(\d+)? WP|(\d+)\.?(\d+)? ([A-Za-zö]+), Rest = ([A-Za-zö]+)|([A-Za-zö]+))\)$/
        )
        if (match) {
          if (match[9]) {
            if (meter1.energy_carrier === heaterDict[match[9]]) {
              meter1.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]),
                year: 2021,
              })
            } else if (meter2.energy_carrier === heaterDict[match[9]]) {
              meter2.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]),
                year: 2021,
              })
            } else {
              rowError.years.push(2020)
              error = true
            }
          } else if (match[7] && match[8]) {
            if (meter1.energy_carrier === heaterDict[match[7]]) {
              meter1.values.push({
                amount: parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2021,
              })
            }
            if (meter2.energy_carrier === heaterDict[match[7]]) {
              meter2.values.push({
                amount: parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2021,
              })
            }
            if (meter1.energy_carrier === heaterDict[match[8]]) {
              meter1.values.push({
                amount: parseInt(match[1]) + parseInt('0.' + match[2]) - parseInt(match[5]) + parseInt('0.' + match[6]),
                year: 2021,
              })
            }
            if (meter2.energy_carrier === heaterDict[match[8]]) {
              meter2.values.push({
                amount:
                  match[1].parseInt() +
                  ('0.' + match[2]).parseInt() -
                  (match[5].parseInt() + ('0.' + match[6]).parseInt()),
                year: 2021,
              })
            }
          } else if (match[3]) {
            meter1.values.push({
              amount:
                match[1].parseInt() +
                ('0.' + match[2]).parseInt() -
                (match[3].parseInt() + ('0.' + match[4]).parseInt()),
              year: 2021,
            })
          }
        } else {
          rowError.years.push(2021)
        }
      } else {
        meter1.values.push({
          amount: row['2021'],
          year: 2021,
        })
      }
    }
    if (
      meter1.id &&
      meter1.meter_type &&
      meter1.values.length > 0 &&
      (meter1.meter_type === 'ELECTRICITY_GENERAL' || meter1.energy_carrier)
    ) {
      meter1.values.forEach((value) => {
        let v = {
          id: id,
          amount: value.amount,
          meter_type: meter1.meter_type,
          year: value.year,
        }
        if (meter1.meter_type === 'END_ENERGY_HEATER') {
          v.energy_carrier = meter1.energy_carrier
        }
        values.push(v)
      })
    }
    if (
      meter2.id &&
      meter2.meter_type &&
      meter2.values.length > 0 &&
      (meter2.meter_type === 'ELECTRICITY_GENERAL' || meter2.energy_carrier)
    ) {
      meter2.values.forEach((value) => {
        let v = {
          id: id,
          amount: value.amount,
          meter_type: meter2.meter_type,
          year: value.year,
        }
        if (meter2.meter_type === 'END_ENERGY_HEATER') {
          v.energy_carrier = meter2.energy_carrier
        }
        values.push(v)
      })
    }
    if (error) {
      input.push({
        input: r,
        error: rowError,
      })
    }
  })
  return { values, input }
}

const parseSustainabilityXlsx = (indicatorSheet, initialSheet, yearSheets, portfolio) => {
  let input = {
    indicators: [],
    years: [],
  }
  let keys = ['pe', 'per', 'penr', 's1e', 's2e', 's3e', 's12e', 's123e']
  const energyCarrierNames = portfolio.energy_carriers.map((ec) => ec.name)
  const defaultEnergyCarrierMap = {
    Heizöl: 'HEATING_OIL',
    Gas: 'NATURAL_GAS',
    Solarthermie: 'SOLAR_HEAT',
    Fernwärme: 'DISTRICT_HEAT',
    Nahwärme: 'LOCAL_HEAT',
    Pellets: 'PELLET',
    Holzschnitzel: 'WOOD_CHIPS',
    Stückholz: 'WOOD',
    Netzstrom: 'ELECTRICITY_GRID',
    'PV-Strom': 'ELECTRICITY_PV',
    Unbekannt: 'UNKNOWN',
  }
  const usageMap = {
    Standard: 'STANDARD',
    Mieter: 'TENANTS',
    Allgemein: 'GENERAL',
    Warmwasser: 'HOT_WATER',
    Raumwärme: 'ROOM_HEAT',
    Einspeisung: 'FEED_IN', // deprecated
    Rückspeisung: 'FEED_IN',
  }
  let sustainabilityOverview = []
  yearSheets.unshift({ year: 0, data: initialSheet })
  yearSheets.forEach((sheet, index) => {
    let sheetError = []
    let year = {
      year: sheet.year,
      entries: [],
    }
    sheet.data.forEach((row, index) => {
      let entry = {
        energy_carrier_name: null,
        usage: null,
        factors: {},
      }
      let entryError = {
        energyCarrierName: false,
        usage: false,
        indicatorValues: [],
      }

      let error = false

      keys.forEach((indicator) => {
        const value = validateInput(row[indicator], /^\d+(?:\.\d+)?$/)
        if (value.match) {
          entry.factors[indicator] = row[indicator]
        } else if (!value.empty) {
          entryError.indicatorValues.push(indicator)
          error = true
        }
      })

      const name = asString(row.energy_carrier_name)
      const usage = asString(row.usage)
      if (!Object.keys(usageMap).includes(usage)) {
        entryError.usage = true
      }
      if (energyCarrierNames.includes(name)) {
        entry.energy_carrier_name = name
        entry.usage = usageMap[usage]
        year.entries.push(entry)
      } else if (name in defaultEnergyCarrierMap) {
        entry.energy_carrier_name = defaultEnergyCarrierMap[name]
        entry.usage = usageMap[usage]
        year.entries.push(entry)
      } else {
        entryError.energyCarrierName = true
        error = true
      }

      if (error) {
        sheetError.push({
          input: row,
          error: entryError,
        })
      }
    })
    if (sheetError.length > 0) {
      input.years.push({ year: sheet.year, errors: sheetError })
    }
    sustainabilityOverview.push(year)
  })
  const data = {
    delete_missing: [],
    sustainability_overview: sustainabilityOverview,
  }
  return { data, input }
}

const importService = {
  readXlsx,
  sheetToJson,
  parseBuildingsXlsx,
  parseMeterTemplateXlsx,
  parseAltTemplate1Xlsx,
  parseAltTemplate2Xlsx,
  parseSustainabilityXlsx,
}

export default importService
