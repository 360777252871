<!--
PortfolioMixin:
Loads a specific portfolio for pages with router argument "portfolio_id"

Provides: portfolio, portfolioLoading, portfolioReady
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    // Properties set by router:
    portfolio_id: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      __getCurrentPortfolio: 'portfolio/getCurrentPortfolio',
      __getSustainabilityIndicators: 'portfolio/getSustainabilityIndicators',
      __getReidaEnergyCarriers: 'portfolio/getReidaEnergyCarriers',
    }),

    portfolio() {
      return this.__getCurrentPortfolio()
    },

    reidaEnergyCarriers() {
      return this.__getReidaEnergyCarriers()
    },

    sustainabilityIndicators() {
      return this.__getSustainabilityIndicators()
    },

    portfolioLoading() {
      return this.portfolio !== undefined && this.portfolio !== null && this.portfolio.__details_loading
    },

    portfolioReady() {
      return (
        // Important: portfolio.id === this.portfolio_id to prevent
        // the mixin from being used for other portfolios
        // for example, when navigating from one portfolio to another
        this.portfolio?.id === this.portfolio_id &&
        this.portfolio !== undefined &&
        this.portfolio !== null &&
        this.portfolio.__details_loaded &&
        !this.portfolio.__details_loading
      )
    },
  },

  watch: {
    portfolio_id() {
      this.__loadPortfolioDetailsById(this.portfolio_id)
      this.__loadSustainabilityIndicatorsByPortfolioId(this.portfolio_id)
      this.__loadReidaEnergyCarriers(this.portfolio_id)
    },
  },

  created() {
    this.__loadPortfolioDetailsById(this.portfolio_id)
    this.__loadSustainabilityIndicatorsByPortfolioId(this.portfolio_id)
    this.__loadReidaEnergyCarriers(this.portfolio_id)
  },

  methods: {
    ...mapActions({
      __loadPortfolioDetailsById: 'portfolio/loadDetailsById',
      __loadSustainabilityIndicatorsByPortfolioId: 'portfolio/loadSustainabilityIndicatorsByPortfolioId',
      __loadReidaEnergyCarriers: 'portfolio/loadReidaEnergyCarriers',
    }),
  },
}
</script>
