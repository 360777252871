<i18n>
  {
    "de": {
      "pendingMessage": "Emissionsfaktoren werden importiert, dieser Vorgang kann einen Moment dauern.",
      "uploadFile": "XLSX-Datei auswählen:",
      "submitImport": "Bestätigen",
      "cancelImport": "Abbrechen",
      "corruptTemplate": "Die ausgewählte Datei entspricht nicht der erwarteten Vorlage.",
      "backLabel": "Zurück",
      "energyCarrierTitle": "Energieträger",
      "peTitle": "Primärenergie",
      "perTitle": "Primärenergie (erneuerbar)",
      "penrTitle": "Primärenergie (nicht erneuerbar)",
      "s1eTitle": "Scope 1 Emissionen",
      "s2eTitle": "Scope 2 Emissionen",
      "s3eTitle": "Scope 3 Emissionen",
      "s12eTitle": "Scope 1-2 Emissionen",
      "s123eTitle": "Scope 1-3 Emissionen",
      "deleteMissingYears": "bereits vorhandene Einträge löschen",
      "initialYear": "Initial",
      "warningText": "Fehlerhafte Felder werden als leer angenommen."
    }
  }
</i18n>

<template>
  <div class="import-indicators">
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else-if="pending">
      {{ $t('pendingMessage') }}
    </div>
    <div v-else-if="report">
      <div v-if="input.years.length > 0">
        {{ $t('warningText') }}
      </div>
      <div v-if="input.years.length > 0">
        <li v-for="(row, index) in input.years" v-bind:key="index" class="year-list">
          <h4 v-if="row.year === 0">{{ $t('initialYear') }}</h4>
          <h4 v-else>{{ row.year }}</h4>
          <DetailList has-header class="sheet-table">
            <template #header>
              <span>{{ $t('energyCarrierTitle') }}</span>
              <span>{{ $t('s1eTitle') }}</span>
              <span>{{ $t('s2eTitle') }}</span>
              <span>{{ $t('s3eTitle') }}</span>
              <span>{{ $t('s12eTitle') }}</span>
              <span>{{ $t('s123eTitle') }}</span>
              <span>{{ $t('peTitle') }}</span>
              <span>{{ $t('penrTitle') }}</span>
              <span>{{ $t('perTitle') }}</span>
            </template>
            <li v-for="(energyCarrier, index) in row.errors" v-bind:key="index" class="indicator-list">
              <span v-bind:class="{ cell: true, 'error-cell': energyCarrier.error.energyCarrierName }">{{
                energyCarrier.input.energy_carrier_name
              }}</span>
              <span v-bind:class="{ cell: true, 'error-cell': energyCarrier.error.usage }">{{
                energyCarrier.input.usage
              }}</span>
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's1e'),
                }"
                >{{ energyCarrier.input.s1e }}</span
              >
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's2e'),
                }"
                >{{ energyCarrier.input.s2e }}</span
              >
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's3e'),
                }"
                >{{ energyCarrier.input.s3e }}</span
              >
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's12e'),
                }"
                >{{ energyCarrier.input.s12e }}</span
              >
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's123e'),
                }"
                >{{ energyCarrier.input.s123e }}</span
              >
              <span
                v-bind:class="{ cell: true, 'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 'pe') }"
                >{{ energyCarrier.input.pe }}</span
              >
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 'penr'),
                }"
                >{{ energyCarrier.input.penr }}</span
              >
              <span
                v-bind:class="{
                  cell: true,
                  'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 'per'),
                }"
                >{{ energyCarrier.input.per }}</span
              >
            </li>
          </DetailList>
        </li>
      </div>
      <CheckInput v-model="deleteMissingYears" :edit="true" :label="$t('deleteMissingYears')" />
      <ButtonWrapper>
        <button class="button" @click="onSubmitImport">{{ $t('submitImport') }}</button>
        <button class="button" @click="onCancelImport">{{ $t('cancelImport') }}</button>
      </ButtonWrapper>
    </div>
    <div v-else-if="corruptTemplate" class="corrupt-message">
      {{ $t('corruptTemplate') }}
      <ButtonWrapper>
        <button class="button" @click="onBack">{{ $t('backLabel') }}</button>
      </ButtonWrapper>
    </div>
    <div v-else>
      {{ $t('uploadFile') }}
      <input id="indicator-field" type="file" accept=".xlsx" @change="onFileChanged" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'

import importService from '@/services/import-service.js'
import CheckInput from '@/components/shared/forms/CheckInput.vue'

export default {
  components: {
    ButtonWrapper,
    DetailList,
    CheckInput,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: false,
      error: null,
      report: false,
      data: null,
      corruptTemplate: false,
      deleteMissingYears: false,
    }
  },

  methods: {
    ...mapActions({
      importSustainabilityIndicatorsAndValues: 'portfolio/importSustainabilityIndicatorsAndValues',
    }),

    onFileChanged(event) {
      const file = event.target.files[0]
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.onXLSXFileRead
      this.fileReader.readAsBinaryString(file)
    },

    async submitImport() {
      this.pending = true
      this.data['delete_missing'] = this.deleteMissingYears ? ['SUSTAINABILITY_OVERVIEW'] : []
      try {
        this.error = null
        await this.importSustainabilityIndicatorsAndValues({
          data: this.data,
          portfolioId: this.portfolio.id,
        })
      } catch (error) {
        this.error = error
      }
    },

    onBack() {
      this.onCancelImport()
    },

    onSubmitImport() {
      this.submitImport()
    },

    onCancelImport() {
      this.data = null
      this.report = false
      this.corruptTemplate = false
    },

    onXLSXFileRead(event) {
      const content = this.fileReader.result
      const workbook = importService.readXlsx(content)
      let indicatorSheet = null
      let initialSheet = null
      if (!workbook.SheetNames.includes('sustainability_indicators')) {
        // custom indicator code
        // this.corruptTemplate = true
      } else {
        indicatorSheet = importService.sheetToJson(workbook.Sheets['sustainability_indicators'], 3)
      }

      if (!workbook.SheetNames.includes('initial')) {
        this.corruptTemplate = true
      } else {
        initialSheet = importService.sheetToJson(workbook.Sheets['initial'], 3)
      }
      if (!this.corruptTemplate) {
        const yearSheets = workbook.SheetNames.filter((n) => n.match(/^\d{2,4}$/)).map((n) => {
          return {
            year: n,
            data: importService.sheetToJson(workbook.Sheets[n], 3),
          }
        })

        const { data, input } = importService.parseSustainabilityXlsx(
          indicatorSheet,
          initialSheet,
          yearSheets,
          this.portfolio
        )

        this.data = data
        this.error = null
        this.report = true
        this.input = input
      }
    },
  },
}
</script>
<style>
.import-indicators {
  margin-bottom: 20px;
}

.year-list {
  list-style-type: none;
}

.cell {
  height: 100%;
}

.error-cell {
  background-color: orange;
}
</style>
