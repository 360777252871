var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"import-indicators"},[(_vm.error)?_c('div',[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.pending)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('pendingMessage'))+" ")]):(_vm.report)?_c('div',[(_vm.input.years.length > 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('warningText'))+" ")]):_vm._e(),(_vm.input.years.length > 0)?_c('div',_vm._l((_vm.input.years),function(row,index){return _c('li',{key:index,staticClass:"year-list"},[(row.year === 0)?_c('h4',[_vm._v(_vm._s(_vm.$t('initialYear')))]):_c('h4',[_vm._v(_vm._s(row.year))]),_c('DetailList',{staticClass:"sheet-table",attrs:{"has-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('energyCarrierTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('s1eTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('s2eTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('s3eTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('s12eTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('s123eTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('peTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('penrTitle')))]),_c('span',[_vm._v(_vm._s(_vm.$t('perTitle')))])]},proxy:true}],null,true)},_vm._l((row.errors),function(energyCarrier,index){return _c('li',{key:index,staticClass:"indicator-list"},[_c('span',{class:{ cell: true, 'error-cell': energyCarrier.error.energyCarrierName }},[_vm._v(_vm._s(energyCarrier.input.energy_carrier_name))]),_c('span',{class:{ cell: true, 'error-cell': energyCarrier.error.usage }},[_vm._v(_vm._s(energyCarrier.input.usage))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's1e'),
              }},[_vm._v(_vm._s(energyCarrier.input.s1e))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's2e'),
              }},[_vm._v(_vm._s(energyCarrier.input.s2e))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's3e'),
              }},[_vm._v(_vm._s(energyCarrier.input.s3e))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's12e'),
              }},[_vm._v(_vm._s(energyCarrier.input.s12e))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 's123e'),
              }},[_vm._v(_vm._s(energyCarrier.input.s123e))]),_c('span',{class:{ cell: true, 'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 'pe') }},[_vm._v(_vm._s(energyCarrier.input.pe))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 'penr'),
              }},[_vm._v(_vm._s(energyCarrier.input.penr))]),_c('span',{class:{
                cell: true,
                'error-cell': energyCarrier.error.indicatorValues.some((i) => i === 'per'),
              }},[_vm._v(_vm._s(energyCarrier.input.per))])])}),0)],1)}),0):_vm._e(),_c('CheckInput',{attrs:{"edit":true,"label":_vm.$t('deleteMissingYears')},model:{value:(_vm.deleteMissingYears),callback:function ($$v) {_vm.deleteMissingYears=$$v},expression:"deleteMissingYears"}}),_c('ButtonWrapper',[_c('button',{staticClass:"button",on:{"click":_vm.onSubmitImport}},[_vm._v(_vm._s(_vm.$t('submitImport')))]),_c('button',{staticClass:"button",on:{"click":_vm.onCancelImport}},[_vm._v(_vm._s(_vm.$t('cancelImport')))])])],1):(_vm.corruptTemplate)?_c('div',{staticClass:"corrupt-message"},[_vm._v(" "+_vm._s(_vm.$t('corruptTemplate'))+" "),_c('ButtonWrapper',[_c('button',{staticClass:"button",on:{"click":_vm.onBack}},[_vm._v(_vm._s(_vm.$t('backLabel')))])])],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t('uploadFile'))+" "),_c('input',{attrs:{"id":"indicator-field","type":"file","accept":".xlsx"},on:{"change":_vm.onFileChanged}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }