<!--
PortfolioBuildingsForListMixin:
Loads buildings for a specific portfolio

Provides: buildings, buildingsForListLoading, buildingsForListLoaded
Required: PortfolioMixin
-->
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      getBuildingsForList: 'portfolioBuildingsForList/getBuildingsForList',
      getBuildingsForListLoading: 'portfolioBuildingsForList/getBuildingsForListLoading',
      getBuildingsForListLoaded: 'portfolioBuildingsForList/getBuildingsForListLoaded',
    }),

    buildingsForList() {
      return this.getBuildingsForList()
    },

    buildingsForListLoading() {
      return this.getBuildingsForListLoading()
    },

    buildingsForListLoaded() {
      return this.getBuildingsForListLoaded()
    },
  },

  watch: {
    portfolio_id() {
      this.loadPortfolioBuildingsForList({ portfolioId: this.portfolio_id })
    },
  },

  created() {
    this.loadPortfolioBuildingsForList({ portfolioId: this.portfolio_id })
  },

  methods: {
    ...mapActions({
      loadPortfolioBuildingsForList: 'portfolioBuildingsForList/load',
    }),
  },
}
</script>
