<i18n>
{
  "de": {
    "uploadFile": "XLSX-Datei auswählen:",
    "pendingMessage": "Portfolio wird importiert, dieser Vorgang kann mehrere Minuten dauern. Wechseln Sie ins Liegenschaften Tab und laden Sie die Seite neu, um die importierten Liegenschaften zu sehen.",
    "submitImport": "Bestätigen",
    "cancelImport": "Abbrechen",
    "confirmSumbitImport": "Neue Liegenschaften können Auswirkungen auf den Preis haben.",
    "buildingCount": " Liegenschaften wurden gefunden.",
    "adminWarning": "Achtung! Administratoren werden beim Importieren auch Reporter-Liegenschaften anpassen. Archivierte Liegenschaften werden ignoriert.",
    "warningText": "Die XLSX-Datei enthält fehlerhafte Eingaben.\nWollen Sie dennoch weiterfahren, dabei werden fehlerhafte Eingaben ignoriert?",
    "invalidLegend": "Ungültiger Input (Wird beim Import ignoriert)",
    "warningLegend": "Ungültiger/Fehlender Input (Es ist empfohlen diese Inputs zu verbessern vor dem Import)",
    "errorLegend": "Ungültiger/Fehlender Input (Diese Inputs müssen verbessert werden)",
    "corruptTemplate": "Bitte verwenden Sie die vorgesehene Datei für den Import.",
    "generalTitle": "Basisdaten - Allgemein",
    "heatingTitle": "Basisdaten - Heizung",
    "pvTitle": "Basisdaten - PV",
    "distributionTitle": "Basisdaten - Wärmeverteilung",
    "componentTitle": "Bauteile Gebäudehülle",
    "zoneTitle": "Nutzung und Flächen",
    "heaterTitle": "Wärmeerzeuger",
    "identifierTitle": "ID",
    "descriptionTitle": "Bezeichnung",
    "addressesTitle": "Adressliste",
    "egidsTitle": "EGID",
    "coordinatesTitle": "Koordinaten",
    "zipCodeTitle": "Postleitzahl",
    "municipalityTitle": "Gemeinde",
    "tagsTitle": "Gruppierung",
    "yearTitle": "Baujahr",
    "activeFromTitle": "Aktiv seit",
    "activeToTitle": "Aktiv bis",
    "floorsNumberTitle": "Anzahl Geschosse",
    "roofTypeTitle": "Dach-Typ",
    "remarksTitle": "Bemerkung",
    "heatingYearTitle": "Letzter Ersatz",
    "replacementYearTitle": "Nächster Ersatz",
    "capacityTitle": "Leistung [kWp]",
    "productionManualTitle": "Ertrag [kWh/kWp]",
    "typeTitle": "Typ",
    "componentTypeTitle": "Bauteil",
    "lastRenovationYearTitle": "Letzte Sanierung",
    "nextRenovationYearTitle": "Nächste Sanierung",
    "renovationCostTitle": "Sanierungskosten",
    "areaTitle": "Bauteilfläche",
    "uvalueTitle": "U-Wert",
    "energyAreaTitle": "EBF [m²]",
    "rentalAreaTitle": "HNF [m²]",
    "floorAreaTitle": "BGF [m²]",
    "rentableAreaTitle": "VMF [m²]",
    "dhwDemandTitle": "Warmwasserbedarf [kWh/m²]",
    "elTenantsTitle": "Mieter [kWh/m²]",
    "elGeneralTitle": "Allg. Gebäudetechnik [kWh/m²]",
    "rhShareTitle": "Anteil Raumwärme [%]",
    "dhwShareTitle": "Anteil Warmwasser [%]",
    "energyCalibrationTitle": "Modellkalibrierung",
    "shareError": "Der totale Deckungsanteil der Raumwärme und/oder des Warmwassers ist nicht für alle Liegenschaften exakt 100%.\nÜberprüfen Sie die Einträge für die Liegenschaften:",
    "zoneError": "Für die folgenden Liegenschaften fehlt ein Eintrag:",
    "missingSheet": "Die XLSX-Datei scheint nicht dem vorgesehenen Formular zu entsprechen.\nDie folgenden Tabellen fehlen in der Datei:"
  }
}
</i18n>

<template>
  <div class="import-buildings">
    <div v-if="error">
      {{ error }}
    </div>
    <div v-else-if="pending">
      {{ $t('pendingMessage') }}
    </div>
    <div v-else-if="report">
      <div v-if="missingSheets.length > 0" class="errorText">
        {{ $t('missingSheet') }}
        <li v-for="(row, index) in missingSheets" :key="index" class="textList">
          <span>{{ $t(row) }}</span>
        </li>
      </div>
      <div v-if="input.generalSheet.length > 0">
        <h3>{{ $t('generalTitle') }}</h3>
        <DetailList has-header class="sheet-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('descriptionTitle') }}</span>
            <span>{{ $t('addressesTitle') }}</span>
            <span>{{ $t('egidsTitle') }}</span>
            <span>{{ $t('coordinatesTitle') }}</span>
            <span>{{ $t('zipCodeTitle') }}</span>
            <span>{{ $t('municipalityTitle') }}</span>
            <span>{{ $t('tagsTitle') }}</span>
            <span>{{ $t('yearTitle') }}</span>
            <span>{{ $t('activeFromTitle') }}</span>
            <span>{{ $t('activeToTitle') }}</span>
            <span>{{ $t('floorsNumberTitle') }}</span>
            <span>{{ $t('roofTypeTitle') }}</span>
            <span>{{ $t('remarksTitle') }}</span>
          </template>
          <li v-for="(row, index) in input.generalSheet" :key="index">
            <span :class="{ 'error-cell': row.error.identifier }">{{ row.input.identifier }}</span>
            <span :class="{ 'invalid-cell': row.error.description }">{{ row.input.description }}</span>
            <span :class="{ 'error-cell': row.error.addresses }">{{ row.input.addresses }}</span>
            <span :class="{ 'invalid-cell': row.error.egids }">{{ row.input.egids }}</span>
            <span :class="{ 'invalid-cell': row.error.coordinates }">{{ row.input.coordinates }}</span>
            <span :class="{ 'error-cell': row.error.zip_code }">{{ row.input.zip_code }}</span>
            <span :class="{ 'invalid-cell': row.error.municipality }">{{ row.input.municipality }}</span>
            <span :class="{ 'invalid-cell': row.error.tags }">{{ row.input.tags }}</span>
            <span :class="{ 'invalid-cell': row.error.building_year }">{{ row.input.building_year }}</span>
            <span :class="{ 'invalid-cell': row.error.active_from }">{{ row.input.active_from }}</span>
            <span :class="{ 'invalid-cell': row.error.active_to }">{{ row.input.active_to }}</span>
            <span :class="{ 'invalid-cell': row.error.floors_number }">{{ row.input.floors_number }}</span>
            <span :class="{ 'invalid-cell': row.error.roof_type }">{{ row.input.roof_type }}</span>
            <span :class="{ 'invalid-cell': row.error.remarks }">{{ row.input.remarks }}</span>
          </li>
        </DetailList>
      </div>
      <div v-if="input.heatingSheet.length > 0">
        <h3>{{ $t('heatingTitle') }}</h3>
        <DetailList has-header class="sheet-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('heatingYearTitle') }}</span>
            <span>{{ $t('replacementYearTitle') }}</span>
            <span>{{ $t('remarksTitle') }}</span>
          </template>
          <li v-for="(row, index) in input.heatingSheet" :key="index">
            <span>{{ row.input.identifier }}</span>
            <span :class="{ cell: true, 'warning-cell': row.error.year }">{{ row.input.year }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.replacement_year }">{{
              row.input.replacement_year
            }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.remarks }">{{ row.input.remarks }}</span>
          </li>
        </DetailList>
      </div>
      <div v-if="input.pvSheet.length > 0">
        <h3>{{ $t('pvTitle') }}</h3>
        <DetailList has-header class="sheet-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('yearTitle') }}</span>
            <span>{{ $t('capacityTitle') }}</span>
            <span>{{ $t('productionManualTitle') }}</span>
            <span>{{ $t('remarksTitle') }}</span>
          </template>
          <li v-for="(row, index) in input.pvSheet" :key="index">
            <span>{{ row.input.identifier }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.year }">{{ row.input.year }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.capacity }">{{ row.input.capacity }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.production_manual }">{{
              row.input.production_manual
            }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.remarks }">{{ row.input.remarks }}</span>
          </li>
        </DetailList>
      </div>
      <div v-if="input.distributionSheet.length > 0">
        <h3>{{ $t('distributionTitle') }}</h3>
        <DetailList has-header class="sheet-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('typeTitle') }}</span>
            <span>{{ $t('yearTitle') }}</span>
            <span>{{ $t('replacementYearTitle') }}</span>
            <span>{{ $t('remarksTitle') }}</span>
          </template>
          <li v-for="(row, index) in input.distributionSheet" :key="index">
            <span>{{ row.input.identifier }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.distribution }">{{ row.input.distribution }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.year }">{{ row.input.year }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.replacement_year }">{{
              row.input.replacement_year
            }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.remarks }">{{ row.input.remarks }}</span>
          </li>
        </DetailList>
      </div>
      <div v-if="input.componentSheet.length > 0">
        <h3>{{ $t('componentTitle') }}</h3>
        <DetailList has-header class="sheet-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('componentTypeTitle') }}</span>
            <span>{{ $t('lastRenovationYearTitle') }}</span>
            <span>{{ $t('nextRenovationYearTitle') }}</span>
            <span>{{ $t('renovationCostTitle') }}</span>
            <span>{{ $t('areaTitle') }}</span>
            <span>{{ $t('uvalueTitle') }}</span>
            <span>{{ $t('remarksTitle') }}</span>
          </template>
          <li v-for="(row, index) in input.componentSheet" :key="index">
            <span :class="{ cell: true, 'warning-cell': row.error.identifier }">{{ row.input.identifier }}</span>
            <span :class="{ cell: true, 'warning-cell': row.error.type }">{{ row.input.type }}</span>
            <span :class="{ cell: true, 'warning-cell': row.error.year }">{{ row.input.year }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.replacement_year }">{{
              row.input.replacement_year
            }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.replacement_costs }">{{
              row.input.replacement_costs
            }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.area }">{{ row.input.area }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.uvalue }">{{ row.input.uvalue }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.remarks }">{{ row.input.remarks }}</span>
          </li>
        </DetailList>
      </div>
      <div v-if="(input.zoneSheet.length > 0) | (input.missingEntries.zoneSheet.length > 0)">
        <h3>{{ $t('zoneTitle') }}</h3>
        <div v-if="input.missingEntries.zoneSheet.length > 0" class="errorText">
          {{ $t('zoneError') }}
          <li v-for="(row, index) in input.missingEntries.zoneSheet" :key="index" class="textList">
            <span>{{ row }}</span>
          </li>
        </div>
        <DetailList has-header class="sheet-table">
          <template #header>
            <span>{{ $t('identifierTitle') }}</span>
            <span>{{ $t('typeTitle') }}</span>
            <span>{{ $t('energyAreaTitle') }}</span>
            <span>{{ $t('rentableAreaTitle') }}</span>
            <span>{{ $t('rentalAreaTitle') }}</span>
            <span>{{ $t('floorAreaTitle') }}</span>
            <span>{{ $t('dhwDemandTitle') }}</span>
            <span>{{ $t('elTenantsTitle') }}</span>
            <span>{{ $t('elGeneralTitle') }}</span>
          </template>
          <li v-for="(row, index) in input.zoneSheet" :key="index">
            <span :class="{ cell: true, 'error-cell': row.error.identifier }">{{ row.input.identifier }}</span>
            <span :class="{ cell: true, 'error-cell': row.error.usage }">{{ row.input.usage }}</span>
            <span :class="{ cell: true, 'warning-cell': row.error.energy_area }">{{ row.input.energy_area }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.rentable_area }">{{ row.input.rentable_area }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.rental_area }">{{ row.input.rental_area }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.floor_area }">{{ row.input.floor_area }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.dhw_demand }">{{ row.input.dhw_demand }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.el_tenants }">{{ row.input.el_tenants }}</span>
            <span :class="{ cell: true, 'invalid-cell': row.error.el_general }">{{ row.input.el_general }}</span>
          </li>
        </DetailList>
      </div>
      <div v-if="(input.heaterSheet.length > 0) | (input.missingEntries.heaterSheet.length > 0)">
        <h3>{{ $t('heaterTitle') }}</h3>
        <div v-if="input.missingEntries.heaterSheet.length > 0" class="errorText">
          {{ $t('shareError') }}
          <li v-for="(row, index) in input.missingEntries.heaterSheet" :key="index" class="textList">
            <span>{{ row.identifier }}</span>
          </li>
        </div>
        <div v-if="input.heaterSheet.length > 0">
          <DetailList has-header class="sheet-table">
            <template #header>
              <span>{{ $t('identifierTitle') }}</span>
              <span>{{ $t('typeTitle') }}</span>
              <span>{{ $t('rhShareTitle') }}</span>
              <span>{{ $t('dhwShareTitle') }}</span>
              <span>{{ $t('energyCalibrationTitle') }}</span>
            </template>
            <li v-for="(row, index) in input.heaterSheet" :key="index">
              <span :class="{ cell: true, 'error-cell': row.error.identifier }">{{ row.input.identifier }}</span>
              <span :class="{ cell: true, 'error-cell': row.error.type }">{{ row.input.type }}</span>
              <span :class="{ cell: true, 'error-cell': row.error.rh_share }">{{ row.input.rh_share }}</span>
              <span :class="{ cell: true, 'error-cell': row.error.dhw_share }">{{ row.input.dhw_share }}</span>
              <span :class="{ cell: true, 'invalid-cell': row.error.energy_calibration }">{{
                row.input.energy_calibration
              }}</span>
            </li>
          </DetailList>
        </div>
      </div>
      <table
        v-if="
          (input.zoneSheet.length > 0) |
            (input.heaterSheet.length > 0) |
            (input.componentSheet.length > 0) |
            (input.distributionSheet.length > 0) |
            (input.pvSheet.length > 0) |
            (input.heatingSheet.length > 0) |
            (input.generalSheet.length > 0)
        "
        class="legend-table"
      >
        <tr>
          <td class="legend-color invalid-cell"></td>
          <td class="legend-text">{{ $t('invalidLegend') }}</td>
        </tr>
        <tr>
          <td class="legend-color warning-cell"></td>
          <td class="legend-text">{{ $t('warningLegend') }}</td>
        </tr>
        <tr>
          <td class="legend-color error-cell"></td>
          <td class="legend-text">{{ $t('errorLegend') }}</td>
        </tr>
      </table>
      <div v-if="!fatal && warning">
        {{ $t('warningText') }}
      </div>
      <div v-if="buildings.length > 0">
        <p>{{ buildings.length + $t('buildingCount') }}</p>
        <p v-if="isAdmin" class="admin-warning">{{ $t('adminWarning') }}</p>
      </div>
      <ButtonWrapper>
        <button v-if="!fatal" class="button" @click="onSubmitImport">{{ $t('submitImport') }}</button>
        <button class="button" @click="onCancelImport">{{ $t('cancelImport') }}</button>
      </ButtonWrapper>
    </div>
    <div v-else-if="corruptTemplate" class="corrupt-message">
      {{ $t('corruptTemplate') }}
    </div>
    <div v-else>
      {{ $t('uploadFile') }}
      <input id="portfolio-field" type="file" accept=".xlsx" @change="onFileChanged" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'

import importService from '@/services/import-service.js'

export default {
  components: {
    ButtonWrapper,
    DetailList,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      pending: false,
      error: null,
      report: false,
      buildings: [],
      tagGroups: [],
      input: null,
      fatal: false,
      corruptTemplate: false,
      missingLines: {
        heaterSheet: [],
        zoneSheet: [],
      },
      missingSheets: [],
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'account/admin',
    }),
    getErrorClass: function (error) {
      return error
    },
  },

  methods: {
    ...mapActions({
      addBuildingsAndTagGroups: 'building/addBuildingsAndTagGroups',
    }),

    onFileChanged(event) {
      const file = event.target.files[0]
      this.fileReader = new FileReader()
      this.fileReader.onloadend = this.onXLSXFileRead
      this.fileReader.readAsBinaryString(file)
    },

    async submitImport() {
      this.pending = true
      try {
        this.error = null
        await this.addBuildingsAndTagGroups({
          buildings: this.buildings,
          tagGroups: this.tagGroups,
          portfolioId: this.portfolio.id,
        })
      } catch (error) {
        this.error = error
      }
    },

    onSubmitImport() {
      if (!confirm(this.$t('confirmSumbitImport'))) {
        return
      }
      this.submitImport()
    },

    onCancelImport() {
      this.data = false
      this.buildings = null
      this.tagGroups = null
      this.reportHtml = ''
      this.fatal = false
      this.report = false
      this.corruptTemplate = false
      this.missingLines.zoneSheet = []
      this.missingLines.heaterSheet = []
      this.missingSheets = []
    },

    onXLSXFileRead(event) {
      const content = this.fileReader.result
      const workbook = importService.readXlsx(content)
      let sheets = {}

      workbook.SheetNames.forEach((name) => {
        switch (name) {
          case 'Basisdaten - Allgemein':
            sheets.generalSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
          case 'Basisdaten - Heizung':
            sheets.heatingSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
          case 'Basisdaten - PV':
            sheets.pvSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
          case 'Basisdaten - Wärmeverteilung':
            sheets.distributionSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
          case 'Bauteile Gebäudehülle':
            sheets.componentSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
          case 'Nutzung und Flächen':
            sheets.zoneSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
          case 'Wärmeerzeuger':
            sheets.heaterSheet = importService.sheetToJson(workbook.Sheets[name], 5)
            break
        }
      })

      const { buildings, tagGroups, input, warning, fatal } = importService.parseBuildingsXlsx(
        sheets,
        this.portfolio.heating_types
      )
      this.tagGroups = tagGroups
      this.buildings = buildings
      this.input = input
      this.warning = warning
      this.fatal = fatal

      if (!sheets.generalSheet) {
        this.fatal = true
        this.missingSheets.push('generalTitle')
      }
      if (!sheets.heatingSheet) {
        this.warning = true
        this.missingSheets.push('heatingTitle')
      }
      if (!sheets.pvSheet) {
        this.warning = true
        this.missingSheets.push('pvTitle')
      }
      if (!sheets.distributionSheet) {
        this.warning = true
        this.missingSheets.push('distributionTitle')
      }
      if (!sheets.componentSheet) {
        this.warning = true
        this.missingSheets.push('componentTitle')
      }
      if (!sheets.zoneSheet) {
        this.fatal = true
        this.missingSheets.push('zoneTitle')
      }
      if (!sheets.heaterSheet) {
        this.fatal = true
        this.missingSheets.push('heaterTitle')
      }

      this.error = null
      this.report = true
    },
  },
}
</script>

<style>
.import-buildings {
  margin-bottom: 20px;
}
.input-report-table {
  width: 100%;
}
.corrupt-message {
  color: red;
}
.legend-table {
  border: none;
  margin-top: 10px;
  margin-bottom: 10px;
}
.cell {
  height: 100%;
}
.textList {
  text-indent: 5px;
  list-style-type: none;
}
.errorText {
  white-space: pre-line;
  color: red;
}
.error-cell {
  background-color: red;
  min-height: 1em;
}
.warning-cell {
  background-color: orange;
  min-height: 1em;
}
.invalid-cell {
  background-color: yellow;
  min-height: 1em;
}
.legend-color {
  border: none;
  width: 50px;
}
.legend-text {
  border: none;
}
th,
td {
  padding: 5px;
}
.import-warning {
  color: red;
}
</style>

<style scoped>
.admin-warning {
  color: var(--warning-color);
}
</style>
