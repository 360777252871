<i18n>
{
  "de": {
    "uploadFile": "XLSX-Datei auswählen:",
    "submitImport": "Bestätigen",
    "cancelImport": "Abbrechen",
    "import": "Importieren",
    "template": "Template auswählen:",
    "labelTemplate": "reguläres Template",
    "labelAltTemplate1": "alternatives Template 1",
    "labelAltTemplate2": "alternatives Template 2",
    "reportTitle": "Mangelhafte Daten",
    "invalidLegend": "Ungültiger Input (wird beim Import ignoriert)",
    "warningLegend": "Die Liegenschaft-ID konnte keiner bestehenden Liegenschaft zugeordnet werden",
    "pendingMessage": "Verbrauchsdaten werden importiert, dieser Vorgang kann einige Minuten dauern, bitte warten...",
    "wrongTemplate": "Die XLSX-Datei entspricht nicht der von Ihnen ausgewählten Vorlage",
    "oilTitle": "Heizöl\r\nVerbrauch\r\nin Liter",
    "gasTitle": "Lieferung Gas\r\nKonto 18470\r\nin kWh",
    "districtHeatingTitle": "Lieferung\r\nFernwärme\r\nKonto 18460\r\nin kWh",
    "heating1Title": "Heizstrom\r\nKonto 18520\r\nin kWh",
    "heating2Title": "Heizstrom\r\nKonto 41282\r\nin kWh",
    "general1Title": "Strom Allgemein\r\nKonto Nr. 18100\r\nin kWh",
    "general2Title": "Strom Allgemein\r\nKonto Nr. 41100\r\nin kWh",
    "garage1Title": "Strom Garage\r\nKonto Nr. 18103\r\nin kWh",
    "garage2Title": "Strom Garage\r\nKonto Nr. 41103\r\nin kWh",
    "vacancyTitle": "Strom Leerstand\r\nKonto Nr. 41115\r\nin kWh",
    "identifier1Title": "Lieg-Nr",
    "description1Title": "Bezeichnung",
    "identifier2Title": "LS-Nr.",
    "locationTitle": "Location",
    "description2Title": "Description",
    "heater1Title": "Heizungsträger 1",
    "heater2Title": "Heizungsträger 2",
    "identifierTitle": "ID",
    "meterTypeTitle": "Typ",
    "energyCarrierTitle": "Energieträger",
    "meterIdentifierTitle": "Datenpunkt-Nr.",
    "startMonthTitle": "Stichmonat",
    "remarksTitle": "Bemerkung",
    "amountTitle": "Wert",
    "valueCount": " Verbrauchswerte gefunden."
  }
}
</i18n>
<template>
  <div v-if="error">
    {{ error }}
  </div>

  <div v-else-if="pending" class="loading">
    <ClipLoader size="16px" color="#000" />
    <div>{{ $t('pendingMessage') }}</div>
  </div>

  <div v-else-if="wrongTemplate" class="wrong-template-message">
    {{ $t('wrongTemplate') }}
    <ButtonWrapper>
      <button class="button" @click="onCancelValues">{{ $t('cancelImport') }}</button>
    </ButtonWrapper>
  </div>

  <div v-else-if="input.length > 0 || values.length > 0">
    <div v-if="input.length > 0">
      <h3>{{ $t('reportTitle') }}</h3>
      <DetailList v-if="selectedTemplate === 'template'" has-header class="sheet-table">
        <template #header>
          <span>{{ $t('identifierTitle') }}</span>
          <span>{{ $t('meterTypeTitle') }}</span>
          <span>{{ $t('energyCarrierTitle') }}</span>
          <span>{{ $t('meterIdentifierTitle') }}</span>
          <span>{{ $t('startMonthTitle') }}</span>
          <span>{{ $t('remarksTitle') }}</span>
          <span>{{ $t('amountTitle') }}</span>
        </template>
        <li v-for="(row, index) in input" :key="index">
          <span :class="{ 'warning-cell': row.error.id }">{{ row.input.id }}</span>
          <span :class="{ 'invalid-cell': row.error.meter_type }">{{ row.input.meter_type }}</span>
          <span :class="{ 'invalid-cell': row.error.energy_carrier }">{{ row.input.energy_carrier }}</span>
          <span :class="{ 'invalid-cell': row.error.identifier }">{{ row.input.identifier }}</span>
          <span :class="{ 'invalid-cell': row.error.start_month }">{{ row.input.start_month }}</span>
          <span :class="{ 'invalid-cell': row.error.remarks }">{{ row.input.remarks }}</span>
          <span :class="{ 'invalid-cell': row.error.amount }">{{ row.input.amount }}</span>
        </li>
      </DetailList>
      <DetailList v-else-if="selectedTemplate === 'altTemplate1'" has-header class="sheet-table">
        <template #header>
          <span>{{ $t('identifier1Title') }}</span>
          <span>{{ $t('description1Title') }}</span>
          <span>{{ $t('districtHeatingTitle') }}</span>
          <span>{{ $t('oilTitle') }}</span>
          <span>{{ $t('gasTitle') }}</span>
          <span>{{ $t('heating1Title') }}</span>
          <span>{{ $t('heating2Title') }}</span>
          <span>{{ $t('general1Title') }}</span>
          <span>{{ $t('general2Title') }}</span>
          <span>{{ $t('garage1Title') }}</span>
          <span>{{ $t('garage2Title') }}</span>
          <span>{{ $t('vacancyTitle') }}</span>
        </template>
        <li v-for="(row, index) in input" :key="index">
          <span :class="{ 'warning-cell': row.error.identifier }">{{ row.input.identifier }}</span>
          <span>{{ row.input.description }}</span>
          <span :class="{ 'invalid-cell': row.error.districtHeating }">{{ row.input.districtHeating }}</span>
          <span :class="{ 'invalid-cell': row.error.oil }">{{ row.input.oil }}</span>
          <span :class="{ 'invalid-cell': row.error.gas }">{{ row.input.gas }}</span>
          <span :class="{ 'invalid-cell': row.error.heating1 }">{{ row.input.heating1 }}</span>
          <span :class="{ 'invalid-cell': row.error.heating2 }">{{ row.input.heating2 }}</span>
          <span :class="{ 'invalid-cell': row.error.general1 }">{{ row.input.general1 }}</span>
          <span :class="{ 'invalid-cell': row.error.general2 }">{{ row.input.general2 }}</span>
          <span :class="{ 'invalid-cell': row.error.garage1 }">{{ row.input.garage1 }}</span>
          <span :class="{ 'invalid-cell': row.error.garage2 }">{{ row.input.garage2 }}</span>
          <span :class="{ 'invalid-cell': row.error.vacancy }">{{ row.input.vacancy }}</span>
        </li>
      </DetailList>
      <DetailList v-else-if="selectedTemplate === 'altTemplate2'" has-header class="sheet-table">
        <template #header>
          <span>{{ $t('identifier2Title') }}</span>
          <span>{{ $t('locationTitle') }}</span>
          <span>{{ $t('description2Title') }}</span>
          <span>{{ 2020 }}</span>
          <span>{{ 2021 }}</span>
          <span>{{ $t('heater1Title') }}</span>
          <span>{{ $t('heater2Title') }}</span>
        </template>
        <li v-for="(row, index) in input" :key="index">
          <span :class="{ 'warning-cell': row.error.identifier }">{{ row.input.identifier }}</span>
          <span>{{ row.input.location }}</span>
          <span :class="{ 'warning-cell': row.error.description }">{{ row.input.description }}</span>
          <span :class="{ 'invalid-cell': row.error.years.find((year) => year === 2020) }">{{ row.input.y2020 }}</span>
          <span :class="{ 'invalid-cell': row.error.years.find((year) => year === 2021) }">{{ row.input.y2021 }}</span>
          <span :class="{ 'warning-cell': row.error.heater1 }">{{ row.input.heater1 }}</span>
          <span :class="{ 'invalid-cell': row.error.heater2 }">{{ row.input.heater2 }}</span>
        </li>
      </DetailList>
      <tr>
        <td class="legend-color invalid-cell"></td>
        <td class="legend-text">{{ $t('invalidLegend') }}</td>
      </tr>
      <tr>
        <td class="legend-color warning-cell"></td>
        <td class="legend-text">{{ $t('warningLegend') }}</td>
      </tr>
    </div>
    <div v-if="values.length > 0" class="confirmation-dialog">
      {{ values.length + $t('valueCount') }}
      <ButtonWrapper>
        <button class="button" @click="onSubmitValues">{{ $t('submitImport') }}</button>
        <button class="button" @click="onCancelValues">{{ $t('cancelImport') }}</button>
      </ButtonWrapper>
    </div>
    <div v-else class="confirmation-dialog">
      <ButtonWrapper>
        <button class="button" @click="onCancelValues">{{ $t('cancelImport') }}</button>
      </ButtonWrapper>
    </div>
  </div>

  <div v-else>
    <ul class="template-selection">
      <li>
        {{ $t('template') }}
      </li>
      <li>
        <v-select
          id="template-options"
          :key="selectedTemplate"
          v-model="selectedTemplate"
          :options="templateOptions"
          :clearable="false"
          :reduce="(t) => t.id"
          @input="onTemplateOptionChange"
        />
      </li>
    </ul>
    {{ $t('uploadFile') }}
    <input id="values-field" type="file" accept=".xlsx" @change="onFileChanged" />
    <ButtonWrapper v-if="file && selectedTemplate !== ''">
      <button class="button" @click="onImport">{{ $t('import') }}</button>
    </ButtonWrapper>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import importService from '@/services/import-service.js'

import ButtonWrapper from '@/components/shared/ButtonWrapper.vue'
import DetailList from '@/components/shared/lists/DetailList.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    ButtonWrapper,
    DetailList,
    ClipLoader,
  },

  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    buildings: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      pending: false,
      error: null,
      wrongTemplate: false,
      input: [],
      values: [],
      file: null,
      fileReader: new FileReader(),
      templateOptions: [
        { id: 'template', label: this.$t('labelTemplate') },
        { id: 'altTemplate1', label: this.$t('labelAltTemplate1') },
        { id: 'altTemplate2', label: this.$t('labelAltTemplate2') },
      ],
      selectedTemplate: '',
    }
  },

  computed: {
    getErrorClass: function (error) {
      return error
    },
  },

  methods: {
    ...mapActions({
      refreshBuildings: 'portfolioBuildingsForList/refresh',
      addMeterValues: 'building/addMeterValues',
    }),

    onTemplateOptionChange(value) {
      this.selectedTemplate = value
      switch (value) {
        case 'template':
          this.fileReader.onloadend = this.onTemplateFileRead
          break
        case 'altTemplate1':
          this.fileReader.onloadend = this.onAltTemplate1FileRead
          break
        case 'altTemplate2':
          this.fileReader.onloadend = this.onAltTemplate2FileRead
      }
    },

    onFileChanged(event) {
      this.file = event.target.files[0]
    },

    onImport() {
      this.fileReader.readAsBinaryString(this.file)
    },

    onSubmitValues() {
      this.submitValues()
    },

    async submitValues() {
      this.pending = true
      let values = []
      for (let i = 0; i < this.values.length; i++) {
        let value = {}
        value['building_id'] = this.values[i].id
        value['year'] = this.values[i].year ? this.values[i].year : this.year
        value['amount'] = this.values[i].convert
          ? this.values[i].amount * this.portfolio.energy_carriers[value.energy_carrier].caloric_value
          : this.values[i].amount
        value['meter_type'] = this.values[i].meter_type
        value['energy_carrier'] = this.values[i].energy_carrier ? this.values[i].energy_carrier : 'HEATING_OIL'
        value['start_month'] = this.values[i].start_month
          ? this.values[i].start_month
          : this.start_month
          ? this.start_month
          : 1
        value['remarks'] = this.values[i].remarks ? this.values[i].remarks : ''
        value['identifier'] = this.values[i].identifier ? this.values[i].identifier : ''
        values.push(value)
      }
      try {
        this.error = null
        await this.addMeterValues({
          values: values,
          portfolioId: this.portfolio.id,
        })
      } catch (error) {
        this.error = error
      }
      if (!this.error) {
        this.pending = false
        this.input = []
        this.values = []
        this.file = null
      }
    },

    onCancelValues() {
      this.error = null
      this.wrongTemplate = false
      this.input = []
      this.values = []
      this.file = null
    },

    async loadBuildings() {
      try {
        this.error = null
        await this.refreshBuildings()
      } catch (error) {
        this.error = error
      }
    },

    onTemplateFileRead() {
      this.loadBuildings()
      const content = this.fileReader.result
      const workbook = importService.readXlsx(content)
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      if (sheet['B1'] && !isNaN(sheet['B1'].v)) {
        this.year = sheet['B1'].v
        const json = importService.sheetToJson(sheet, 5)
        let buildingIds = {}
        this.buildings.forEach((building) => {
          buildingIds[building.identifier] = building.id
        })
        const energyCarrierNameList = this.portfolio.energy_carriers.map((ec) => ec.name)
        const { values, input } = importService.parseMeterTemplateXlsx(json, buildingIds, energyCarrierNameList)
        this.input = input
        this.values = values
      } else {
        this.wrongTemplate = true
      }
    },

    onAltTemplate1FileRead() {
      this.loadBuildings()
      const content = this.fileReader.result
      const workbook = importService.readXlsx(content)
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      if (sheet['D1']) {
        const match = sheet['D1'].v.match(/^(?:per )(\d{1,2})\.(\d{1,2})\.(\d{4})$/)
        if (match && match.length === 4) {
          let date = new Date(parseInt(match[3]), parseInt(match[2]) - 1, parseInt(match[1]))
          date.setDate(date.getDate() - 364)
          this.start_month = date.getMonth()
          this.year = date.getFullYear()
        } else {
          this.wrongTemplate = true
        }
      } else {
        this.wrongTemplate = true
      }
      const json = importService.sheetToJson(sheet, 3)
      let buildingIds = {}
      this.buildings.forEach((building) => {
        buildingIds[building.identifier] = building.id
      })
      const { values, input } = importService.parseAltTemplate1Xlsx(json, buildingIds)
      this.input = input
      this.values = values
    },

    onAltTemplate2FileRead() {
      this.loadBuildings()
      const content = this.fileReader.result
      const workbook = importService.readXlsx(content)
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      if (sheet['A1']) {
        const match = sheet['A1'].v.match(/^LS-Nr\.$/)
        if (match) {
          this.start_month = 1
        } else {
          this.wrongTemplate = true
        }
      } else {
        this.wrongTemplate = true
      }
      const json = importService.sheetToJson(sheet, 1)
      let buildingIds = {}
      this.buildings.forEach((building) => {
        buildingIds[building.identifier] = building.id
      })
      const { values, input } = importService.parseAltTemplate2Xlsx(json, buildingIds)
      this.input = input
      this.values = values
    },
  },
}
</script>

<style lang="scss">
.loading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-xs);
}
.import-meter-values {
  margin-bottom: 20px;
}
#template-options {
  width: 180px;
  margin-bottom: 10px;
  margin-left: 5px;
}
.template-selection > li {
  display: inline-block;
}
.confirmation-dialog {
  margin-top: 10px;
}
</style>
